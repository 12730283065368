export const BG_PRIMARY = 'rgba(231,230,231,1)';
export const BG_SECONDARY = 'rgba(96,92,92,1)';
export const BG_ALTERNATIVE = 'rgba(50,47,47,1)';

export const TEXT_PRIMARY = '#352023';
export const TEXT_SECONDARY = '#FFF';
export const TEXT_PRIMARY_LIGHT = '#FFF';

export const BTN_NAVIGATION_COLOR = '#877a7a';

// Opacity layer for left content (Desktop view)
export const BG_SECONDARY_TRANSPARENT = '';
