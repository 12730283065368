import { extendTheme } from '@chakra-ui/react';
import {
  BG_SECONDARY,
  BG_PRIMARY,
  BG_SECONDARY_TRANSPARENT,
  BG_ALTERNATIVE,
  TEXT_PRIMARY,
  TEXT_SECONDARY,
  TEXT_PRIMARY_LIGHT,
} from '@/constants/colors';
import { FONT_FAMILY } from '@/constants/default-props';

/**
 * override chackra-ui for personalize purpose
 * see the docs: https://chakra-ui.com/guides/using-fonts
 */
const theme = {
  fonts: {
    heading: FONT_FAMILY.heading,
    subHeading: FONT_FAMILY.subHeading,
    body: FONT_FAMILY.body,
    body_lv2: FONT_FAMILY.body_lv2,
    fullName: FONT_FAMILY.fullName,
    jost: `'Jost', serif`,
  },
  fontSizes: {
    heading: '36px',
    subHeading: '26px',
    body: '16px',
    body_lv2: '18px',
    fullName: '26px',
  },
  fontWeights: {
    heading: 700,
    subHeading: 700,
    body: 400,
    body_lv2: 700,
    fullName: 700,
  },
  styles: {
    global: {
      ':root': {
        '--fontItalic-heading': '',
        '--fontItalic-subHeading': '',
        '--fontItalic-body': '',
        '--fontItalic-body_lv2': '',
        '--fontItalic-fullName': '',
      },
    },
  },
  colors: {
    bgPrimary: BG_PRIMARY,
    bgSecondary: BG_SECONDARY,
    bgAlternative: BG_ALTERNATIVE,
    mainColorText: TEXT_PRIMARY,
    secondaryColorText: TEXT_SECONDARY,
    mainColorTextLight: TEXT_PRIMARY_LIGHT,
    transparent: 'transparent',
    theme: {
      bgPrimaryText: TEXT_PRIMARY,
      bgPrimary: BG_PRIMARY,
      bgSecondary: BG_SECONDARY,
      bgSecondaryTransparent: BG_SECONDARY_TRANSPARENT,
    },
    button: {
      secondary: {
        500: BG_SECONDARY,
      },
      secondaryDark: {
        500: BG_ALTERNATIVE,
      },
    },
  },
  shadows: {
    outline: BG_ALTERNATIVE,
  },
};

export default extendTheme(theme);
